import { cn } from '@/utils/cn';

import { T } from '@/components/ui/Typography';
import { useMutation } from '@tanstack/react-query';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { readNotification } from './fetchClientNotifications';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogFooter,
  DialogDescription,
} from '@/components/ui/dialog';
import { useState } from 'react';

type NotificationItemProps = {
  title: string;
  description: string;
  href?: string;
  onClick?: () => void;
  image: string;
  isRead: boolean;
  createdAt: string;
  isNew: boolean;
  notificationId: string;
  onHover: () => void;
};

export function NotificationItem({
  title,
  description,
  href,
  image,
  isRead,
  isNew,
  onClick,
  createdAt,
  notificationId,
  onHover,
}: NotificationItemProps) {
  const router = useRouter();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { mutate: mutateReadMutation } = useMutation(
    async () => {
      return await readNotification(notificationId);
    },
    {
      onSuccess: () => {
        router.refresh();
      },
    },
  );
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const content = (
    <div
      onMouseOver={onHover}
      onClick={openDialog}
      className={cn(
        ' flex justify-between items-center w-full text-gray-900 dark:text-white px-6 border-b border-gray-300/75 dark:border-gray-700/75',
        isRead
          ? 'bg-gray-100/50 dark:bg-gray-800/50'
          : 'bg-white dark:bg-gray-900',
      )}
    >
      <div className="flex justify-between items-start w-full  pt-1 ">
        <div className="flex py-2 pb-3 items-start w-full">
          <img
            src={image}
            alt={title}
            className="h-14 w-14 rounded-2xl border-2 mr-4"
          />
          <div className="mr-3 mt-1">
            <T.P className=" font-bold dark:text-white mb-0.5 leading-none">
              {title}
            </T.P>
            <T.Small className=" font-medium text-muted-foreground">
              {description}
            </T.Small>
            <T.Subtle className="text-xs mt-0.5 text-gray-400 dark:text-gray-600 font-medium tracking-wide">
              {createdAt}
            </T.Subtle>
          </div>
        </div>

        {isNew && (
          <div className="flex items-center justify-center h-3 w-3 mt-4 rounded-full bg-red-500 dark:bg-red-500"></div>
        )}
      </div>
    </div>
  );
  return (
    <div className="w-full flex flex-col items-center">
      {href ? (
        <Link onClick={() => mutateReadMutation()} href={href} className="w-full">
          {content}
        </Link>
      ) : (
        <div className="w-full" onClick={onClick}>
          {content}
        </div>
      )}

      {/* Dialog (Popup) */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
<path fill="#DB8509" d="M24 34.001A4 4 0 1 0 24 42.001 4 4 0 1 0 24 34.001zM26 11c0 1.104-.896 2-2 2l0 0c-1.104 0-2-.896-2-2V7c0-1.104.896-2 2-2l0 0c1.104 0 2 .896 2 2V11z"></path><path fill="#BC6F0A" d="M27.887,38.91C27.955,38.617,28,38.314,28,38.001c0-0.383-0.07-0.746-0.172-1.097C26.609,36.965,25.333,37,24,37s-2.609-0.035-3.829-0.096C20.071,37.255,20,37.618,20,38.001c0,0.313,0.045,0.616,0.113,0.909C21.584,38.979,22.926,39,24,39S26.416,38.979,27.887,38.91z"></path><path fill="#FFC107" d="M33,33V16.801c0-4.97-4.029-9-9-9s-9,4.03-9,9V33H33z"></path><path fill="#FFC107" d="M41,33c0,2.209-7.059,4-17,4S7,35.209,7,33s7.059-4,17-4S41,30.791,41,33z"></path><path fill="#FFC107" d="M7,33c0-1.999,8-9.001,8-12s18-3.001,18,0s8,9.999,8,12S7,34.999,7,33z"></path><path fill="#FFE082" d="M9.275,29.424c-0.491,0.593-0.929,1.146-1.295,1.65c1.622,0.655,8.15,3.062,16.562,3.062c4.746,0,10.089-0.78,15.476-3.064c-0.362-0.499-0.796-1.046-1.281-1.632C25.699,34.724,12.698,30.68,9.275,29.424z"></path>
</svg>

          <DialogHeader>
            <DialogTitle>Notification Summary</DialogTitle>
            <DialogClose onClick={closeDialog} />
          </DialogHeader>

          {/* Reusing the image and content layout inside the dialog */}
          <div className="flex py-4 items-start">
           
            <div>
              <T.P className="font-bold dark:text-white mb-1 leading-none">
                {title}
              </T.P>
              <T.Small className="font-medium text-muted-foreground">
                {description}
              </T.Small>
              <T.Subtle className="text-xs mt-0.5 text-gray-400 dark:text-gray-600 font-medium tracking-wide">
                {createdAt}
              </T.Subtle>
            </div>
          </div>

          <DialogFooter>
            
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
