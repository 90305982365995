'use client';
import * as Sentry from '@sentry/nextjs';
import {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { useTheme } from 'next-themes';
import { useRef } from 'react';
import { toast } from 'sonner';

type MutationFn<TData, TVariables> = MutationFunction<TData, TVariables>;

interface ToastMutationOptions<TData, TError, TVariables>
  extends UseMutationOptions<TData, TError, TVariables> {
  loadingMessage?: string | ((variables: TVariables) => string);
  successMessage?: string | ((data: TData, variables: TVariables) => string);
  errorMessage?: string | ((error: TError, variables: TVariables) => string);
}

export function useToastMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
>(
  mutationFn: MutationFn<TData, TVariables>,
  options?: ToastMutationOptions<TData, TError, TVariables>,
): UseMutationResult<TData, TError, TVariables> {




  const toastIdRef = useRef<string | number | null>(null);
  const { theme } = useTheme();
  const isDarkTheme = theme == 'dark';

  const toastStyle = isDarkTheme
? { background: 'white', color: 'black' }
: { background: 'black', color: 'white' };
  return useMutation<TData, TError, TVariables>(mutationFn, {
    ...options,
    onMutate: async (variables) => {
      const loadingMessage = options?.loadingMessage
        ? typeof options.loadingMessage === 'function'
          ? options.loadingMessage(variables)
          : options.loadingMessage
        : 'Loading...';

      toastIdRef.current = toast.loading(loadingMessage,{ position: 'top-center',style:toastStyle });

      if (options?.onMutate) {
        await options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      // router.refresh();
      const successMessage = options?.successMessage
        ? typeof options.successMessage === 'function'
          ? options.successMessage(data, variables)
          : options.successMessage
        : 'Success!';
      toast.success(successMessage, {
        id: toastIdRef.current ?? undefined,
        position: 'top-center',
        style:toastStyle
      });
      toastIdRef.current = null;
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      const errorMessage = options?.errorMessage
        ? typeof options.errorMessage === 'function'
          ? options.errorMessage(error, variables)
          : options.errorMessage
        : 'Error!';
      Sentry.captureException(error);
      toast.error(errorMessage, {
        id: toastIdRef.current ?? undefined,
        position: 'top-center',
        style:toastStyle
      });
      toastIdRef.current = null;
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
}
